<template>
  <b-card no-body class="card-company-table">
    <b-card-header>
      <b-card-title>Most Active Profiles</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0"> </b-card-text>
      <span v-if="spinnerPlayStop == true" style="margin-right: 25px">
        <b-spinner label="Loading..." />
      </span>
      <span class="d-flex">
        <span class="font-weight-bolder" style="font-size: 12px">{{
        this.date + " "
      }}</span>
        <span style="margin-left: 10px; font-size: 12px" class="font-weight-bolder">{{ " " + gradeName }}</span>
      </span>
    </b-card-header>

    <b-table :items="dashboardTable" id="custom-table" :per-page="perPage" :current-page="currentPage" responsive
      :fields="fields" class="mb-0">
      <template #cell(name)="data">
        <div class="d-flex align-items-center">
          <feather-icon icon="EyeIcon" v-b-modal.modal-app-user-logs @click="getProfileLogs(data.item)" size="17"
            class="qu-icon-grey mr-1 curser-pointer" />
          <b-link :to="{
        path: '/user-report/' + data.item.userkey,
      }">
            <div>
              <div class="font-weight-bolder">
                {{ data.item.name }}
              </div>
            </div>
          </b-link>
          <div v-if="data.item.gender">({{ data.item.gender }})</div>
        </div>
      </template>
      <template #cell(source_country)="data">
        <div class="d-flex align-items-center">
          <div>
            <span v-if="data.item.userBy" class="mr-1">
              <img v-if="data.item.userBy == 'sign-up-with-gmail'" style="width: 15px" src="/googleIcon.png"
                alt="google-icon" />
              <img v-if="data.item.userBy == 'sign-up-with-apple'" style="width: 15px" src="/appleIcon.png"
                alt="google-icon" />
              <feather-icon v-if="data.item.userBy == 'create-account'" size="15" style="color: #6fbfff"
                icon="KeyIcon" /> </span>

            <span v-if="data.item.devicePlatform" class="mr-1">
              <img v-if="data.item.devicePlatform == 'ios'" style="width: 15px" src="/ios.png" alt="ios-icon" />
              <img v-if="data.item.devicePlatform == 'android'" style="width: 15px" src="/devicon_android.png"
                alt="android-icon" /> </span>

            <span v-if="data.item.source_country">
              <flag-component :countryCode="data.item.source_country" size="15"/>
            </span>
          </div>
        </div>
      </template>

      <template #cell(time)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.Time }}
            </div>
          </div>
        </div>
      </template>
    </b-table>
    <div style="display: flex; flex-direction: row-reverse">
      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="custom-table"
        size="sm"></b-pagination>
    </div>
    <b-modal id="modal-app-user-logs" size="xl" centered :title="modalTitle" hide-footer @hidden="resetModal">
      <div style="overflow: hidden" v-if="appLogs.data && appLogs.data.length > 0">
        <b-table-simple hover caption-top responsive class="rounded-bottom mb-0">
          <b-thead head-variant="light">
            <b-tr>
              <b-th style="width: 100px;">Grade</b-th>
              <b-th style="width: 200px;">Type</b-th>
              <b-th style="width: 100px;">subject</b-th>
              <b-th style="width: 300px;">topic</b-th>
              <b-th style="width: 150px;">screen</b-th>
              <b-th style="width: 300px;">Click</b-th>
              <b-th style="width: 200px;">created</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-for="(log, index) in appLogs.data">
              <b-tr :key="index">
                <b-td>
                  <div v-if="log.grade">
                    {{ log.grade }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </b-td>
                <b-td>
                  <div v-if="log.logType">
                    {{ log.logType }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </b-td>
                <b-td>
                  <div v-if="log.subject">
                    {{ log.subject }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </b-td>
                <b-td>
                  <div v-if="log.topic">
                    {{ log.topic }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </b-td>
                <b-td>
                  <div v-if="log.screen">
                    {{ log.screen }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </b-td>
                <b-td>
                  <div v-if="log.clickedOnSubject">{{ log.clickedOnSubject }}</div>
                  <div v-else-if="log.clickedOnTopic">{{ log.clickedOnTopic }}</div>
                  <div v-else-if="log.clickedOnLevel">{{ log.clickedOnLevel }}</div>
                  <div v-else-if="log.clickedOn">{{ log.clickedOn }}</div>
                  <div v-else="log.clickedOn">{{ log.clickedOn }}</div>
                </b-td>
                <b-td>
                  <div v-if="log.created">
                    {{ log.created }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
        <div class="d-flex justify-content-end">
          <pagination class="mt-1 mb-0" :data="appLogs" :limit=2 @pagination-change-page="profileLog">
          </pagination>
        </div>
      </div>
      <div v-else class="text-center mb-2">No records found</div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BTable,
  BAvatar,
  BImg,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BFormInput,
  BMediaAside,
  BMediaBody,
  BDropdown,
  BPagination,
  BDropdownItem,
  BSpinner,
  BLink,
  BModal,
  BTableSimple,
  BTh,
  BTbody,
  BTr,
  BThead,
  BTd
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import FlagComponent from "../components/FlagComponent.vue";
import axios from "axios";

export default {
  components: {
    BTd,
    BTableSimple,
    BTh,
    BTbody,
    BTr,
    BThead,
    BModal,
    FlagComponent,
    BButton,
    BCard,
    BTable,
    BSpinner,
    BLink,
    BCardHeader,
    DateRangePicker,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormInput,
    BMedia,
    BPagination,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    sendDatesPropsToAllComponent: {
      type: Object,
      required: true,
    },
  },
  watch: {
    sendDatesPropsToAllComponent(val) {
      if (this.sendDatesPropsToAllComponent.date) {
        this.selected = this.sendDatesPropsToAllComponent.gradeId;
        if (this.selected.className == "All") {
          this.gradeName = "All -> All Grades";
        }
        let first =
          this.sendDatesPropsToAllComponent.date.startDate.toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }
          );

        let last =
          this.sendDatesPropsToAllComponent.date.endDate.toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }
          );
        this.date = first + " - " + last;
        this.date = this.date.replaceAll("/", "-");
        this.dateRange = this.sendDatesPropsToAllComponent.date;
        this.GET_MOST_POPUPLAR_User(
          this.sendDatesPropsToAllComponent.date
        );
      } else {
        this.GET_MOST_POPUPLAR_User(this.sendDatesPropsToAllComponent);
      }
    },
  },
  data() {
    return {
      spinnerPlayStop: true,
      perPage: 10,
      currentPage: 1,
      dateRange: {},
      gradeName: "All -> All Grades",
      date: "",
      rangePicker: ["2022-05-01", "2022-05-10"],
      Picker: "2022-05-01 to 2022-05-10",
      selected: "",
      fields: [
        { key: "name", label: "Name" },
        { key: "source_country", label: "Sources" },
        { key: "time", label: "TIME" },
      ],
      dashboardTable: [],
      bearerToken: null,
      appLogs: [],
      selectedUser: {
        name: ''
      },
    };
  },
  computed: {
    rows() {
      return this.dashboardTable.length;
    },
    modalTitle() {
      return `${this.selectedUser.name} Activity`;
    },
  },

  mounted() {
    this.bearerToken = "Bearer " + localStorage.getItem("_t");
  },

  methods: {
    resetModal() {
      this.appLogs =  [];
    },
    async getProfileLogs(user) {
      this.selectedUser = user;
      this.profileLog();
    },
    async profileLog(page = 1) {
      const payload = { id: this.selectedUser.userkey };
      try {
        const resp = await axios.post(process.env.VUE_APP_API_URL + `/zape-app/logs?page=${page}`, payload, {
          headers: {
            Authorization: this.bearerToken,
          },
        });
        this.appLogs = resp.data.logs;
      } catch (error) {
        console.log('error', error);
      }
    },
    GET_MOST_POPUPLAR_User(value) {
      this.spinnerPlayStop = true;
      var startDate = value.startDate;
      var endDate = value.endDate;
      var month = startDate.getMonth() + 1;
      var day = startDate.getDate();
      var year = startDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var startDate = year + "-" + month + "-" + day;
      var month = endDate.getMonth() + 1;
      var day = endDate.getDate();
      var year = endDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var lastDate = year + "-" + month + "-" + day;
      if (startDate == lastDate) {
        var datetime = {
          date: `${startDate}`,
          gradeId: this.selected._id,
        };
      } else {
        var datetime = {
          date: `${startDate + " " + "to" + " " + lastDate}`,
          gradeId: this.selected._id,
        };
      }
      axios
        .post(
          process.env.VUE_APP_API_URL + "/most-popular/users",
          datetime,
          {
            headers: {
              Authorization: this.bearerToken,
            },
          }
        )
        .then((response) => {
          let resData = response.data;
          this.dashboardTable = resData;
          this.spinnerPlayStop = false;
        });
    },
  },
};
</script>
